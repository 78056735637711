import { ActivitiesData, CardData } from "./types";
import {
  ZAP_CONTRACT_ABI,
  ZAP_CONTRACT_ADDRESS,
} from "../contracts/zap.contract";
import {
  AIRDROP_CONTRACT_ABI,
  AIRDROP_CONTRACT_ADDRESS,
} from "src/contracts/airdrop.contract";
import {
  SYMBIOTIC_CONTRACT_ABI,
  SYMBIOTIC_CONTRACT_ADDRESS,
} from "src/contracts/symbiotic.contract";

export const formatCompactNumber = (value: number | string): string => {
  const numValue = typeof value === "string" ? parseFloat(value) : value;

  if (numValue >= 1e9) {
    return (numValue / 1e9)?.toFixed(1).replace(/\.0$/, "") + "B";
  }
  if (numValue >= 1e6) {
    return (numValue / 1e6)?.toFixed(1).replace(/\.0$/, "") + "M";
  }
  if (numValue >= 1e3) {
    return (numValue / 1e3)?.toFixed(1).replace(/\.0$/, "") + "K";
  }
  return numValue?.toString();
};

export const cardData: CardData[] = [
  {
    id: 1,
    strategy: "EigenLayer Staking",
    token: "ETH",
    vaultAddress: "0x17144556fd3424EDC8Fc8A4C940B2D04936d17eb",
    protocol: [
      {
        name: "EigenLayer",
        image: "/tokenImages/eigenlayer.svg",
      },
      {
        name: "Lido",
        image: "/tokenImages/lido.svg",
      },
    ],
    contractAddress: ZAP_CONTRACT_ADDRESS,
    contractAbi: ZAP_CONTRACT_ABI,
    depositFunction: "depositToEigen",
    amountFunction: "eigenStrat",
    queueWithdrawFunction: "withdrawFromEigen",
    completeWithdrawFunction: "completeEigenWithdrawal",
    checkCooldownFunction: "checkEigenCooldown",
  },
  {
    id: 2,
    strategy: "EtherFi Staking",
    token: "stETH/ETH",
    vaultAddress: "0x35fA164735182de50811E8e2E824cFb9B6118ac2",
    protocol: [
      {
        name: "EtherFi",
        image: "/tokenImages/etherfi.svg",
      },
      {
        name: "Lido",
        image: "/tokenImages/lido.svg",
      },
    ],
    contractAddress: ZAP_CONTRACT_ADDRESS,
    contractAbi: ZAP_CONTRACT_ABI,
    amountFunction: "eETHStrat",
    depositFunction: "depositToEETH",
    queueWithdrawFunction: "withdrawFromEETH",
    completeWithdrawFunction: "completeWithdrawFromEETH",
    checkCooldownFunction: "checkEETHRequestId",
  },
  {
    id: 3,
    strategy: "EtherFi Liquid ETH Yield",
    token: "eETH/weETH/WETH",
    vaultAddress: "0xeA1A6307D9b18F8d1cbf1c3Dd6aad8416C06a221",
    protocol: [
      {
        name: "EtherFi",
        image: "/tokenImages/etherfi.svg",
      },
    ],
    contractAddress: ZAP_CONTRACT_ADDRESS,
    contractAbi: ZAP_CONTRACT_ABI,
    depositFunction: "dummyString",
    queueWithdrawFunction: "queueWithdrawDummy",
    completeWithdrawFunction: "completeWithdrawDummy",
    checkCooldownFunction: "checkEigenCooldown",
  },
  {
    id: 4,
    strategy: "EtherFi Symbiotic LRT",
    token: "eETH/weETH/WETH...",
    vaultAddress: "0x917ceE801a67f933F2e6b33fC0cD1ED2d5909D88",
    protocol: [
      {
        name: "EtherFi",
        image: "/tokenImages/etherfi.svg",
      },
      {
        name: "Symbiotic",
        image: "/tokenImages/symbiotic.svg",
      },
    ],
    contractAddress: SYMBIOTIC_CONTRACT_ADDRESS,
    contractAbi: SYMBIOTIC_CONTRACT_ABI,
    depositFunction: "deposit",
    queueWithdrawFunction: "queueWithdrawal",
    completeWithdrawFunction: "finishWithdrawal",
    checkCooldownFunction: "checkEETHRequestId",
  },
  {
    id: 5,
    strategy: "Ethena USDe Staking",
    token: "USDT/USDC",
    vaultAddress: "0x9D39A5DE30e57443BfF2A8307A4256c8797A3497",
    protocol: [
      {
        name: "Ethena",
        image: "/tokenImages/ethena.svg",
      },
    ],
    contractAddress: ZAP_CONTRACT_ADDRESS,
    contractAbi: ZAP_CONTRACT_ABI,
    amountFunction: "sUSDeStrat",
    depositFunction: "depositToSUSDe",
    queueWithdrawFunction: "withdrawFromSUSDe",
    completeWithdrawFunction: "completeWithdrawFromSUSDe",
    checkCooldownFunction: "checkSUSDeCooldown",
  },
];

export const formatWalletAddress = (address: string | null) => {
  if (!address) return null;
  return `${address.slice(0, 6)}...${address.slice(-4)}`;
};

export const activitiesData: ActivitiesData[] = [
  {
    name: "Linea",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    maxParticipants: 120000,
    contractAddress: AIRDROP_CONTRACT_ADDRESS,
    contractAbi: AIRDROP_CONTRACT_ABI,
    lockUpDuration: "12 months",
    amountRaised: "13.56M",
    estimatedAirdropTimeline: "12 March 2020",
    APY: "112.04%",
    TVL: "$3.56M",
    tokenValue: "$0.00005674",
    tokenMarketCap: "12M",
  },
  {
    name: "Astro",
    description:
      "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    maxParticipants: 95000,
    contractAddress: AIRDROP_CONTRACT_ADDRESS,
    contractAbi: AIRDROP_CONTRACT_ABI,
    lockUpDuration: "6 months",
    amountRaised: "10.22M",
    estimatedAirdropTimeline: "15 April 2020",
    APY: "98.76%",
    TVL: "$2.80M",
    tokenValue: "$0.00006543",
    tokenMarketCap: "10M",
  },
  {
    name: "Orion",
    description:
      "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    maxParticipants: 150000,
    contractAddress: AIRDROP_CONTRACT_ADDRESS,
    contractAbi: AIRDROP_CONTRACT_ABI,
    lockUpDuration: "18 months",
    amountRaised: "20.00M",
    estimatedAirdropTimeline: "20 May 2020",
    APY: "130.45%",
    TVL: "$5.10M",
    tokenValue: "$0.00004567",
    tokenMarketCap: "15M",
  },
  {
    name: "Nebula",
    description:
      "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    maxParticipants: 110000,
    contractAddress: AIRDROP_CONTRACT_ADDRESS,
    contractAbi: AIRDROP_CONTRACT_ABI,
    lockUpDuration: "9 months",
    amountRaised: "12.45M",
    estimatedAirdropTimeline: "25 June 2020",
    APY: "105.32%",
    TVL: "$3.00M",
    tokenValue: "$0.00005432",
    tokenMarketCap: "11M",
  },
  {
    name: "Galaxy",
    description:
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.",
    maxParticipants: 130000,
    contractAddress: AIRDROP_CONTRACT_ADDRESS,
    contractAbi: AIRDROP_CONTRACT_ABI,
    lockUpDuration: "12 months",
    amountRaised: "15.00M",
    estimatedAirdropTimeline: "30 July 2020",
    APY: "115.78%",
    TVL: "$4.20M",
    tokenValue: "$0.00005234",
    tokenMarketCap: "13M",
  },
];
