import * as Dialog from "@radix-ui/react-dialog";
import * as Label from "@radix-ui/react-label";
import { Cross2Icon } from "@radix-ui/react-icons";
import { useEffect, useState } from "react";
import OneClickDropdown from "./OneClickDropdown";
import DepositButton from "./DepositButton";
import { Loader2 } from "lucide-react";
import { Line, LineChart, XAxis, YAxis } from "recharts";
import { useWeb3Auth } from "../../utils/hooks";
import { toast } from "sonner";
import Web3 from "web3";
import { CardData } from "src/utils/types";
import { formatCompactNumber } from "../../utils/utils";
import { useAccount, useBalance, useWriteContract } from "wagmi";
import { useConnectModal } from "@rainbow-me/rainbowkit";

interface DepositModalProps {
  data: CardData;
  disabled?: boolean;
  apyChart: any;
  tvlChart: any;
  apyAvg: number;
  address: any;
  balance: any;
  etherFiTvl?: any;
}

const DepositModal = ({
  data,
  disabled = false,
  apyChart,
  tvlChart,
  apyAvg,
  address,
  balance,
  etherFiTvl,
}: DepositModalProps) => {
  const [token, setToken] = useState({
    name: "ETH",
    img: "/eth.svg",
  });

  const [depositAmount, setDepositAmount] = useState<number>(0);

  const [chain, setChain] = useState({
    chain: "Ethereum",
    img: "/eth.svg",
  });
  const [modalState, setModalState] = useState<
    "idle" | "loading" | "success" | "error" | "retry" | "insufficientBalance"
  >("idle");

  const buttonCopy = {
    idle: `${!address ? "Connect your wallet" : "Deposit"}`,
    loading: <Loader2 className="h-6 w-6 animate-spin" />,
    error: "Transaction Failed",
    retry: `Try Again !`,
    insufficientBalance: "Insufficient Balance",
  };


  const { connector } = useAccount();

  const handleSymbioticDeposit = async () => {
    if (modalState === "success" || modalState === "loading") return;
    if (Number(balance?.formatted) < depositAmount) {
      toast.error("Insufficient Balance");
      setModalState("insufficientBalance");
      return;
    }

    const provid: any = await connector?.getProvider();
    const web3 = new Web3(provid);

    try {
    } catch (error: any) {
      toast.error(error.message);
      console.log(error);
      setModalState("error");
      setTimeout(() => {
        setModalState("retry");
      }, 3500);
    }
  };

  const handleDeposit = async () => {
    if (modalState === "success" || modalState === "loading") return;
    if (Number(balance?.formatted) < depositAmount) {
      toast.error("Insufficient Balance");
      setModalState("insufficientBalance");
      return;
    }

    setModalState("loading");

    const provid: any = await connector?.getProvider();
    const web3 = new Web3(provid);

    const contract = new web3.eth.Contract(
      data.contractAbi,
      data.contractAddress
    );

  
    try {
      const tx = await contract.methods[data.depositFunction]().send({
        from: address,
        value: web3.utils.toWei(depositAmount.toString(), "ether"),
      });
      console.log(tx, "tx");
      setModalState("success");
    } catch (error: any) {
      toast.error(error.message);
      console.log(error);

      setModalState("error");
      setTimeout(() => {
        setModalState("retry");
      }, 3500);
    }
  };

  const [open, setOpen] = useState(false);

  const { openConnectModal } = useConnectModal();
  const { isConnected } = useAccount();

  return (
    <Dialog.Root
      onOpenChange={!isConnected ? openConnectModal : setOpen}
      open={open}
    >
      <Dialog.Trigger disabled={disabled} asChild>
        <button
          disabled={disabled}
          className="text-center font-semibold disabled:bg-inactiveButton cursor-pointer disabled:cursor-not-allowed  bg-highlight transition-all duration-200 inline-flex py-3 items-center justify-center rounded-full  px-[15px]  leading-none disabled:text-textSecondary text-white hover:bg-highlight/90  focus:outline-none"
        >
          Deposit
        </button>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="bg-black/40 backdrop-blur-sm z-30 data-[state=open]:animate-overlayShow fixed inset-0" />
        <Dialog.Content className="data-[state=open]:animate-contentShow fixed top-[50%] border border-cardStroke left-[50%] max-h-[90vh] w-[90vw] max-w-[450px] translate-x-[-50%] translate-y-[-50%] rounded-3xl  bg-white p-6 shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] z-30 focus:outline-none">
          {modalState === "success" ? (
            <>
              <div className=" text-center py-6 px-10">
                <h2 className=" text-2xl ">Deposit Transaction Succesful</h2>
              </div>
              <img src="/loadingIcon.svg" className="mx-auto" alt="" />
              <div className=" text-sm mt-6 ">
                <div className=" flex justify-between items-center">
                  <p className=" text-textSecondary ">Token</p>
                  <p>{data.token}</p>
                </div>
                <div className=" flex pt-2   justify-between items-center">
                  <p className=" text-textSecondary ">Chain(s)</p>
                  <div className="flex gap-1">
                    <img src="/eth.svg" alt="" />

                    <p>Ethereum</p>
                  </div>
                </div>
                <div className=" flex pt-2 justify-between items-center">
                  <p className=" text-textSecondary ">Protocol</p>
                  <p>
                    <div className="flex gap-2">
                      {data.protocol.map((protocol: any) => (
                        <div className="flex gap-2">
                          <img
                            src={protocol.image}
                            className=" w-4 h-4 "
                            alt=""
                          />
                          <p>{protocol.name}</p>
                        </div>
                      ))}
                    </div>
                  </p>
                </div>
                <div className=" flex pt-2 justify-between items-center">
                  <p className=" text-textSecondary ">Amount Deposited</p>
                  <p>
                    <div className="flex gap-2">
                      {depositAmount.toFixed(5)} ETH
                    </div>
                  </p>
                </div>
              </div>
              <div className="flex flex-col gap-4 py-4">
                <Dialog.Close asChild>
                  <button className=" py-3 text-white text-center font-semibold bg-highlight transition-all duration-200 rounded-3xl  ">
                    Share
                  </button>
                </Dialog.Close>
                <Dialog.Close asChild>
                  <button
                    onClick={() => setOpen(false)}
                    className=" py-3  text-center font-semibold bg-highlight transition-all duration-200 rounded-3xl  "
                  >
                    Continue
                  </button>
                </Dialog.Close>
              </div>
            </>
          ) : (
            <>
              <Dialog.Title className="m-0">
                <div className="flex gap-3 ">
                  <div className="flex">
                    {data.protocol.map((protocol: any, index: number) => (
                      <img
                        src={protocol.image}
                        className={`${index > 0 ? "z-10  -translate-x-2 " : ""}`}
                        alt=""
                      />
                    ))}
                  </div>
                  <p className=" "> {data.strategy}</p>
                </div>
              </Dialog.Title>
              <div className=" text-sm mt-6 ">
                <div className=" flex justify-between items-center">
                  <p className=" text-textSecondary ">Token</p>
                  <p>{data.token}</p>
                </div>
                <div className=" flex pt-2   justify-between items-center">
                  <p className=" text-textSecondary ">Chain(s)</p>
                  <div className="flex gap-1">
                    <img src="/eth.svg" alt="" />

                    <p>Ethereum</p>
                  </div>
                </div>
                <div className=" flex pt-2 justify-between items-center">
                  <p className=" text-textSecondary ">Protocol</p>
                  <p>
                    <div className="flex gap-2">
                      {data.protocol.map((protocol: any) => (
                        <div className="flex gap-2">
                          <img
                            src={protocol.image}
                            className=" w-4 h-4 "
                            alt=""
                          />
                          <p>{protocol.name}</p>
                        </div>
                      ))}
                    </div>
                  </p>
                </div>
              </div>

              <div className=" flex justify-stretch flex-col  w-full mt-5 gap-2 text-sm ">
                <div className=" bg-cardStroke flex-1 flex text-sm rounded-2xl p-2 ">
                  <div className="flex flex-1 flex-col">
                    <p className=" text-textSecondary ">APY</p>
                    <p>{apyAvg}%</p>
                  </div>
                  <LineChart width={256} height={65} data={apyChart}>
                    <Line
                      type="monotone"
                      dot={false}
                      dataKey="apy"
                      stroke="#FF6666"
                      isAnimationActive={true}
                      animationDuration={1500}
                      animationEasing="ease-in-out"
                    />
                  </LineChart>
                </div>
                <div className=" bg-cardStroke flex-1 flex text-sm rounded-2xl p-2  ">
                  <div className="flex flex-1 flex-col">
                    <p className=" text-textSecondary ">TVL</p>
                    <p>
                      {tvlChart?.[0]?.tvl > 0 &&
                        formatCompactNumber(tvlChart?.[0]?.tvl)}
                    </p>
                    <p>
                      {data.strategy === "EigenLayer Staking" && "1.1B"}
                      {data?.strategy === "Ethena USDe Staking" && "825M"}
                      {data?.strategy === "EtherFi Staking" && etherFiTvl}
                    </p>
                  </div>
                  <LineChart width={256} height={65} data={tvlChart}>
                    <XAxis dataKey={"date"} />
                    <Line
                      type="monotone"
                      dot={false}
                      dataKey="tvl"
                      stroke="#9475FF"
                      isAnimationActive={true}
                      animationDuration={1500}
                      animationEasing="ease-in-out"
                    />
                  </LineChart>
                </div>
              </div>

              <hr className=" my-4 border-white/5  w-full" />

              <h3 className="">Deposit your Assets:</h3>

              <form action="">
                <Label.Root
                  className=" text-xs inline text-white"
                  htmlFor="firstName"
                >
                  Add Amount
                </Label.Root>
                <input
                  value={depositAmount}
                  onChange={(e) => setDepositAmount(Number(e.target.value))}
                  placeholder="0.02ETH"
                  type="number"
                  className="px-2 w-full outline-none mt-2 py-3 rounded-3xl  bg-cardStroke border border-inputBorder "
                />
                <div className="flex text-sm py-4 justify-between items-center">
                  <p>Balance:</p>
                  <p>
                    {Number(balance?.formatted).toFixed(2)}
                    {balance?.symbol}
                  </p>
                </div>
              </form>
              <DepositButton
                handler={handleDeposit}
                modalState={modalState}
                setModalState={setModalState}
                buttonCopy={buttonCopy}
                disabled={false}
              />
              <Dialog.Close asChild>
                <button
                  className="text-violet11 focus:shadow-violet7 absolute top-[10px] right-[10px] inline-flex h-[25px] w-[25px] appearance-none items-center justify-center rounded-full focus:shadow-[0_0_0_2px] focus:outline-none"
                  aria-label="Close"
                >
                  <Cross2Icon />
                </button>
              </Dialog.Close>
            </>
          )}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default DepositModal;
