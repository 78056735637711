import moment from 'moment-timezone';

const HistoryElement = ({history}:any) => {
    
    return (
        <div className='' >
            <div className="px-2 py-4">
                <h2 className='text-xs' >
                    {history?.strategy_name}
                </h2>
                <hr className='my-1 border-secondaryBg '  />
                <div className=" text-xs flex flex-col gap-2 ">
                    <div className=" flex justify-between items-center ">
                        <p className=' text-textSecondary ' >Type</p>
                        <p className='text-[#6A70FF]' >{history?.type}</p>
                    </div>
                    <div className=" flex justify-between items-center ">
                        <p className=' text-textSecondary ' >Amount</p>
                        <p className='text-white' >{history?.amount}</p>
                    </div>
                    <div className=" flex justify-between items-center ">
                        <p className=' text-textSecondary ' >Date</p>
                        <p className='text-white' >{moment(history?.date).format('DD MMMM yyyy')}</p>
                    </div>
                </div>
            </div>
            <hr className='border-[#383838]' />
        </div>
    )
}

export default HistoryElement