import { useEffect, useState } from "react";
// IMP START - Quick Start
// import { CHAIN_NAMESPACES, IProvider, WEB3AUTH_NETWORK } from "@web3auth/base";
// IMP END - Quick Start

import YieldCard from "../components/YieldCard";
import { cardData } from "../utils/utils";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import CardSkeleton from "src/components/common/CardSkeleton";
import { useAccount, useBalance } from "wagmi";
import axios from "axios";
import Web3 from "web3";
import { formatEther } from 'viem'


function Dashboard() {

    const { address, isConnected,connector } = useAccount();

    const { data: balanceData } = useBalance({
        address: address,
        query: {
            enabled: !!isConnected
        }
    });



    return (
        <div className=" w-full ">


            <div className=" px-6 xl:px-0 pt-6  w-full xl:w-[80%] mx-auto   " >
                <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4  ">
                    {
                        cardData.map((card, index) => (
                            <YieldCard address={address} balance={balanceData} deposited={index == 0 ? false : true} card={card} />
                        ))
                    }
                </div>
            </div>

        </div>
    );
}

export default Dashboard;
