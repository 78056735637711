import { FC } from 'react'
import { Outlet } from 'react-router-dom';
import Navbar from '../common/Navbar';
import Footer from '../common/Footer';
import { useWeb3Auth } from '../../utils/hooks';


const AppLayout: FC = () => {



    return (
        <div className='relative min-h-screen flex flex-col w-full bg-primary text-[#292942]'>
           
            <header>
                <Navbar 
                />
            </header>
            <main className="max-w-[1440px] lg:mt-28 mt-44  relative mb-20  w-full mx-auto">
                <Outlet />
            </main>
            <footer>
                <Footer />
            </footer>
        </div>
    )
}

export default AppLayout;