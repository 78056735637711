import { FC, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import AppLayout from "./components/layouts/AppLayout";
import Dashboard from "./pages/Dashboard";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Toaster } from "sonner";
import Activities from "./pages/Activities";
import { mainnet } from "wagmi/chains";
import {
  RainbowKitProvider,
  darkTheme,
  getDefaultConfig,
} from "@rainbow-me/rainbowkit";
import { WagmiProvider, http } from "wagmi";
import "@rainbow-me/rainbowkit/styles.css";
import {
  coinbaseWallet,
  injectedWallet,
  metaMaskWallet,
  rainbowWallet,
  walletConnectWallet,
} from "@rainbow-me/rainbowkit/wallets";
import Portfolio from "./pages/Portfolio";

const App: FC = () => {
  const queryClient = new QueryClient();

  const wagmiConfig = getDefaultConfig({
    appName: "One Click Club",
    projectId: process.env.REACT_APP_WC_ID as string,
    chains: [mainnet],
    transports: {
      [mainnet.id]: http(),
    },
    wallets: [
      {
        groupName: "Recommended",
        wallets: [
          rainbowWallet,
          coinbaseWallet,
          metaMaskWallet,
          walletConnectWallet,
          injectedWallet,
        ],
      },
    ],
  });

  return (
    <Suspense>
      <WagmiProvider config={wagmiConfig}>
        <QueryClientProvider client={queryClient}>
          <RainbowKitProvider
            theme={darkTheme({
              accentColor: "#8b69fe",
              accentColorForeground: "white",
              borderRadius: "small",
              fontStack: "system",
              overlayBlur: "small",
            })}
          >
            <Routes>
              <Route path="/" element={<AppLayout />}>
                <Route index element={<Dashboard />} />
                <Route path="/portfolio" element={<Portfolio />} />
                <Route path="/activity" element={<Activities />} />
              </Route>
            </Routes>
          </RainbowKitProvider>
        </QueryClientProvider>
        <Toaster />
      </WagmiProvider>
    </Suspense>
  );
};

export default App;
