import React, { FC } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useWeb3Auth } from "../../utils/hooks";
import clsx from "clsx";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useEffect, useMemo } from "react";
import { useAccount } from "wagmi";

// login, loggedIn, isInitialized, account, logout add all these to the NavbarProps

const Navbar: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isConnected } = useAccount();
  useEffect(() => {
    if (!isConnected && location.pathname === "/portfolio") {
      navigate("/");
    }
  }, [isConnected, location.pathname]);
  const navs = useMemo(() => {
    return [
      { name: "Yield Sacking", link: "/", visible: true },
      { name: "Airdrop Farming Coming Soon", link: "/404", visible: true, disabled:true },
      { name: "Portfolio", link: "/portfolio", visible: isConnected },
    ];
  }, [isConnected]);
  return (
    <>
      <nav className="bg-primary text-sm  fixed top-0 left-0 right-0 z-30  ">
        <div className="flex flex-col lg:flex-row md:px-7 md:py-4 py-2 ">
          <div className=" flex justify-between items-center w-full px-3 md:px-0">
            <div className="flex items-center gap-4">
              <Link to="/">
                <img src="/logo.svg" className="mr-8 w-[100px]" alt="" />
              </Link>
              <div className="hidden lg:block">
                {navs.map(
                  (nav, index) =>
                    nav.visible && (
                      <Link
                        to={nav.link}
                        key={index}
                        className={clsx("transition-all duration-200 bg-white rounded-full p-4 font-semibold ", {
                          "": location.pathname === nav.link,
                          "text-textSecondary hover:text-textSecondary/80":
                            location.pathname !== nav.link,
                        })}
                      >
                        {nav.name}
                      </Link>
                    )
                )}
              </div>
            </div>
            <div className="flex items-center gap-4">
              {/* {account && <span className="text-white">{account}</span>}
              <button
                className={` ${!isInitialized ? "bg-white/20" : "bg-white"} hover:bg-highlight hover:text-white transition-all duration-200 text-primary py-[6px]  px-3 rounded-lg `}
                onClick={loggedIn ? logout : login}
                disabled={!isInitialized} // Disable button if not initialized
              >
                {loggedIn ? 'Connected' : 'Connect Wallet'}
              </button> */}
              <ConnectButton label="Sign Up" />
            </div>
          </div>
          <div className="items-center gap-2 lg:hidden navlist py-2 text-center mt-2 justify-center overflow-x-auto border-t border-b border-strokeGray flex">
            {navs.map((nav, index) => (
              <Link
                to={nav.link}
                key={index}
                className={clsx(" transition-all flex-1 duration-200 px-2", {
                  "text-white": location.pathname === nav.link,
                  "text-textSecondary hover:text-textSecondary/80":
                    location.pathname !== nav.link,
                })}
              >
                {nav.name}
              </Link>
            ))}
          </div>
        </div>
        <div className=" py-3  px-4 flex flex-col lg:flex-row md:px-7  border-b  ">
          <span className=" lg:text-xl orbitron-400 font-semibold  ">
            Earn RiskLess Yields
          </span>
          <span className=" lg:border-l lg:ml-[13px] font-['ESKlarheitGroteskMono']  border-white/[8%] text-[#808080] lg:text-base font-normal lg:pl-[13px] ">
            Explore multi-chain vaults.
          </span>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
