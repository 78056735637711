import * as Dialog from '@radix-ui/react-dialog';
import * as Label from '@radix-ui/react-label'
import { Cross2Icon } from '@radix-ui/react-icons';
import { useEffect, useState } from 'react';
import OneClickDropdown from '../DespositModal/OneClickDropdown';
import DepositButton from '../DespositModal/DepositButton';
import { Loader2 } from 'lucide-react';
import { Line, LineChart } from 'recharts';
import { useWeb3Auth } from 'src/utils/hooks';
import { toast } from 'sonner';
import Web3 from 'web3';
import { ASSET_CONTRACT_ABI } from 'src/contracts/asset.contract';
import { useAccount } from 'wagmi';
import { formatUnits, parseUnits } from 'viem';
import { useConnectModal } from '@rainbow-me/rainbowkit';

const ActivityModal = ({ data, disabled }: any) => {
  const [token, setToken] = useState({
    name: 'ETH',
    img: '/eth.svg'
  })
  const [chain, setChain] = useState({
    chain: 'Ethereum',
    img: '/eth.svg'
  })


  const [modalState, setModalState] = useState<"idle" | "loading" | "success" | "error" | "retry" | "insufficientBalance">("idle");
  const [depositAmount, setDepositAmount] = useState(0)
  const [balance, setBalance] = useState(0)
  const [isApproved, setIsApproved] = useState<boolean>(false); // Add state for approval status

  const [assetContractAddress, setAssetContractAddress] = useState('')

  const buttonCopy = {
    idle: "Deposit",
    loading: <Loader2 className="h-6 w-6 animate-spin" />,
    error: "Transaction Failed",
    retry: `Try Again !`,
    insufficientBalance: "Insufficient Balance"
  };

  const apyData = [
    {
      "apy": 1.6264936136700727,
      "date": "Sat, 20 Jul 2024 00:00:00 GMT"
    },
    {
      "apy": 0.6035701152165236,
      "date": "Wed, 10 Jul 2024 00:00:00 GMT"
    },
    {
      "apy": 6.508426077032942,
      "date": "Sun, 30 Jun 2024 00:00:00 GMT"
    },
    {
      "apy": 5.29296183760178,
      "date": "Sat, 15 Jun 2024 00:00:00 GMT"
    },
    {
      "apy": 9.431126889312523,
      "date": "Sun, 09 Jun 2024 00:00:00 GMT"
    },
    {
      "apy": 5.7867547475416705,
      "date": "Mon, 03 Jun 2024 00:00:00 GMT"
    },
    {
      "apy": 3.936732593307805,
      "date": "Wed, 29 May 2024 00:00:00 GMT"
    },
    {
      "apy": 12.959680939840098,
      "date": "Wed, 24 Apr 2024 00:00:00 GMT"
    },
    {
      "apy": 22.158155325812018,
      "date": "Tue, 09 Apr 2024 00:00:00 GMT"
    },
    {
      "apy": 6.335566366654774,
      "date": "Wed, 27 Mar 2024 00:00:00 GMT"
    },
    {
      "apy": 0,
      "date": "Sun, 17 Mar 2024 00:00:00 GMT"
    }
  ]


  const { isConnected, address, connector } = useAccount()

  useEffect(() => {
    if (address) {
      getAssetDetails()

    }
  }, [isConnected])


  const getAssetDetails = async () => {
    if (!connector) return;
    const provid: any = await connector.getProvider()
    const web3 = new Web3(provid)

    const contract = new web3.eth.Contract(data.contractAbi, data.contractAddress);

    try {
      const tx: any = await contract.methods.asset().call();
      console.log(tx, 'tx');
      setAssetContractAddress(tx);
      getUsdcBalance(tx)
      checkIsApproved(tx)
    } catch (error) {
      console.log(error);
    }
  }

  const getUsdcBalance = async (add: any) => {
    const provid: any = await connector?.getProvider()
    const web3 = new Web3(provid)

    const assetInstance = new web3.eth.Contract(ASSET_CONTRACT_ABI, add);
    try {
      const balance: any = await assetInstance.methods.balanceOf(address).call();
      console.log(balance, 'balance');
      setBalance(Number(formatUnits(BigInt(balance), 6)));
    } catch (error) {
      console.log(error);

    }

  }

  const chainData: any = [{
    chain: 'Ethereum',
    img: '/eth.svg'
  }]

  const tokenData: any = [{
    name: 'ETH',
    img: '/eth.svg'
  }]


  const approvalHandler = async () => {
    if (!connector) return;
    const provid: any = await connector.getProvider()
    const web3 = new Web3(provid)

    const contract = new web3.eth.Contract(ASSET_CONTRACT_ABI, assetContractAddress);

    try {
      const tx = await contract.methods.approve(data.contractAddress, '100000000000000000000').send({ from: address });
      console.log(tx, 'tx');
      setIsApproved(true);
    } catch (error) {
      console.log(error);
    }

  }


  const checkIsApproved = async (add: any) => {
    const provid: any = await connector?.getProvider()
    const web3 = new Web3(provid)

    const contract = new web3.eth.Contract(ASSET_CONTRACT_ABI, add);

    try {
      const tx: any = await contract.methods.allowance(address, data.contractAddress).call();
      console.log(tx, 'allowance');
      //change later
      console.log('remove below code');

      setIsApproved(tx > 1000);
    } catch (error) {
      console.log(error);
    }

  }
  const handleDeposit = async () => {

    if (modalState === "success" || modalState === "loading") return;
    if (Number(balance) < depositAmount) {
      toast.error('Insufficient Balance')
      setModalState("insufficientBalance");
      return
    }

    setModalState("loading");


    const provid: any = await connector?.getProvider()
    const web3 = new Web3(provid)
    console.log(data, 'data');
    console.log(data.contractAddress, 'contractAddress');

    const contract = new web3.eth.Contract(data.contractAbi, data.contractAddress);

    console.log(contract.methods, 'contract');


    try {

      // const tx = await contract.methods[data.depositFunction]().estimateGas({ from: address, value: web3.utils.toWei(depositAmount.toString(), 'ether') });
      console.log(parseUnits(depositAmount.toString(), 6).toString());

      const tx = await contract.methods.deposit(parseUnits(depositAmount.toString(), 6).toString(), address).send({ from: address });

      console.log(tx, 'tx1231');
      setModalState("success");
    } catch (error: any) {
      console.log(error);

      setModalState("error");
      setTimeout(() => {
        setModalState("retry");
      }, 3500);
    }

  }



  const { openConnectModal } = useConnectModal();
  const [open, setOpen] = useState(false)


  return (
    <Dialog.Root open={open} onOpenChange={!isConnected? openConnectModal : setOpen}  >
      <Dialog.Trigger disabled={disabled } asChild>
        <button disabled={disabled} className="text-center w-full font-semibold disabled:bg-white/50 bg-[#4B2DFF] mt-3 text-white  transition-all duration-200 inline-flex py-3 items-center justify-center rounded-3xl  px-[15px]  leading-none   focus:outline-none">
          { 'Deposit'}
        </button>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="bg-black/60 backdrop-blur-sm z-30 data-[state=open]:animate-overlayShow fixed inset-0" />
        <Dialog.Content className="data-[state=open]:animate-contentShow fixed top-[50%] left-[50%] max-h-[90vh] w-[90vw] max-w-[450px] translate-x-[-50%] translate-y-[-50%] rounded-3xl p-6 bg-white shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] z-30 focus:outline-none">
          {
            modalState === 'success' ? (
              <>
                <div className=" text-center py-6 px-10">
                  <h2 className=' text-2xl '>Deposit Transaction Succesfull</h2>
                  <p className=' text-sm text-textSecondary pt-3 ' >Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
                </div>
                <img src="/loadingIcon.svg" className='mx-auto' alt="" />
                <div className=" text-sm mt-6 ">
                  <div className="flex justify-between mb-2">
                    <span className=' text-textSecondary'>Max Participants</span>
                    <span>{data.maxParticipants}</span>
                  </div>
                  <div className="flex justify-between mb-2">
                    <span className=' text-textSecondary'>Lock-up duration</span>
                    <span>{data.lockUpDuration}</span>
                  </div>
                  <div className="flex justify-between mb-2">
                    <span className=' text-textSecondary'>Amount Raised</span>
                    <span>{data.amountRaised}</span>
                  </div>
                  <div className="flex justify-between mb-2">
                    <span className=' text-textSecondary'>Estimated Airdrop Timeline</span>
                    <span>{data.estimatedAirdropTimeline}</span>
                  </div>
                </div>
                <div className="flex flex-col gap-4 py-4">
                  <Dialog.Close asChild>
                    <button className=' py-3  text-center font-semibold bg-highlight transition-all duration-200 rounded-3xl  '>Share</button>
                  </Dialog.Close>
                  <Dialog.Close asChild>
                    <button className=' py-3  text-center font-semibold bg-highlight transition-all duration-200 rounded-3xl  '>Continue</button>
                  </Dialog.Close>
                </div>
              </>
            ) : (
              <>
                <Dialog.Title className="m-0">
                  <div className="flex gap-3 items-center">
                    <div className="w-8 h-8 rounded-full bg-black"></div>
                    <p className='text-[20px]'>{data.name}</p>
                  </div>
                </Dialog.Title>
                <div className=" text-sm mt-6 ">
                  <div className="flex justify-between mb-2">
                    <span className=' text-textSecondary'>Max Participants</span>
                    <span>{data.maxParticipants}</span>
                  </div>
                  <div className="flex justify-between mb-2">
                    <span className=' text-textSecondary'>Lock-up duration</span>
                    <span>{data.lockUpDuration}</span>
                  </div>
                  <div className="flex justify-between mb-2">
                    <span className=' text-textSecondary'>Amount Raised</span>
                    <span>{data.amountRaised}</span>
                  </div>
                  <div className="flex justify-between mb-2">
                    <span className=' text-textSecondary'>Estimated Airdrop Timeline</span>
                    <span>{data.estimatedAirdropTimeline}</span>
                  </div>
                </div>

                <div className=" flex justify-stretch flex-col  w-full mt-5 gap-2 text-sm ">
                  <div className=" bg-cardStroke flex-1 flex text-sm rounded-3xl p-2 ">
                    <div className="flex flex-1 flex-col">
                      <p className=' text-textSecondary ' >APY</p>
                      <p>112.04%</p>
                    </div>
                    <LineChart width={256} height={65} data={apyData}>
                      <Line type="monotone" dot={false} dataKey="apy" stroke="#FF6666" isAnimationActive={true}
                        animationDuration={1500}
                        animationEasing="ease-in-out" />
                    </LineChart>

                  </div>
                  <div className=" bg-cardStroke flex-1 flex text-sm rounded-3xl p-2  ">
                    <div className="flex flex-1 flex-col">
                      <p className=' text-textSecondary ' >APY</p>
                      <p>112.04%</p>
                    </div>
                    <LineChart width={256} height={65} data={apyData}>
                      <Line type="monotone" dot={false} dataKey="apy" stroke="#9475FF" isAnimationActive={true}
                        animationDuration={1500}
                        animationEasing="ease-in-out" />
                    </LineChart>
                  </div>
                </div>

                <hr className=' my-4 border-white/5  w-full' />

                <h3 className='' >Deposit your Assets:</h3>

                <form action="">
                  <Label.Root className="inline text-xs text-white" htmlFor="firstName">
                    Add Amount
                  </Label.Root>
                  <input value={depositAmount} onChange={(e: any) => (setDepositAmount(e.target.value))} placeholder='0.2ETH' type="text" className='px-2 py-3 block mt-3 w-full rounded-3xl hover:text-white/  bg-cardStroke border border-inputBorder ' />
                  {/* <OneClickDropdown data={chainData} chain={chain} setChain={setChain} /> */}
                  <div className="flex text-sm py-4 justify-between items-center">
                    <p>Balance:</p>
                    <p>{Number(balance).toFixed(2)} USDC</p>
                  </div>
                </form>
                {
                  !isApproved ? (
                    <button onClick={approvalHandler} className=' py-3 w-full  text-center font-semibold bg-highlight transition-all duration-200 rounded-3xl  '>Approve</button>
                  ) : (
                    <DepositButton handler={handleDeposit} modalState={modalState} setModalState={setModalState} buttonCopy={buttonCopy} disabled={false} />
                  )
                }
                <Dialog.Close asChild>
                  <button
                    className="text-violet11 focus:shadow-violet7 absolute top-[10px] right-[10px] inline-flex h-[25px] w-[25px] appearance-none items-center justify-center rounded-full focus:shadow-[0_0_0_2px] focus:outline-none"
                    aria-label="Close"
                  >
                    <Cross2Icon />
                  </button>
                </Dialog.Close>
              </>
            )
          }


        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

export default ActivityModal