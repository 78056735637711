import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_URL;

const getNonce = async ({ queryKey }: any) => {
    const [_, walletAddress] = queryKey;
    try {
        const { data } = await axios.get(`${API_BASE_URL}/auth/nonce/${walletAddress}`);
        return data;
    } catch (error) {
        console.error('Error fetching nonce:', error);
        throw error;
    }
};

export const useNonce = (walletAddress: string) => {
    return useQuery({ queryKey: ['nonce', walletAddress], queryFn: getNonce });
};

const verifyWallet = async ({ queryKey }: any) => {
    const [_, { walletAddress, signature }] = queryKey;
    try {
        const { data } = await axios.post(`${API_BASE_URL}/auth/verify`, { wallet_address: walletAddress, signature });
        return data;
    } catch (error) {
        console.error('Error verifying wallet:', error);
        throw error;
    }
};

export const useVerifyWallet = (walletAddress: string, signature: string) => {
    return useQuery({ queryKey: ['verifyWallet', { walletAddress, signature }], queryFn: verifyWallet });
};

const getApyData = async ({ queryKey }: any) => {
    const [_, vaultAddress] = queryKey;
    try {
        const data = await axios.get(`${API_BASE_URL}/apy/${vaultAddress}`);
        return data;
    } catch (error) {
        console.error('Error fetching APY data:', error);
        throw error;
    }
};



export const useApyData = (vaultAddress: string) => {
    return useQuery({ queryKey: ['apyData', vaultAddress], queryFn: getApyData });
};


const fetchApyDataForAddress = async (vaultAddress: string) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/apy/${vaultAddress}`);
        return response.data; // Assuming you want to return the data property of the response
    } catch (error) {
        console.error('Error fetching APY data for address:', vaultAddress, error);
        throw error;
    }
};

export const useApyDataForMultipleAddresses = (vaultAddresses: string[],enabled:any) => {
    const queryFn = async () => {
        const promises = vaultAddresses.map(fetchApyDataForAddress);
        try {
            const results = await Promise.all(promises);
            return results;
        } catch (error) {
            console.error('Error fetching APY data for multiple addresses:', error);
            throw error;
        }
    };

    return useQuery({
        queryKey: ['apyDataMultipleAddresses', ...vaultAddresses],
        queryFn,
        enabled:enabled
    });
};