import { useEffect, useState } from 'react'
import ActivityModal from './ActivityModal'
import { Line, LineChart } from 'recharts'
import { useWeb3Auth } from 'src/utils/hooks';
import Web3 from 'web3';
import { AIRDROP_CONTRACT_ABI, AIRDROP_CONTRACT_ADDRESS } from 'src/contracts/airdrop.contract';
import { toast } from 'sonner';
import WithdrawActivityModal from './WithdrawActivityModal';
import { useAccount } from 'wagmi';
import { formatUnits, parseUnits } from 'viem';

const ActivityCard = ({ card, type = 'deposit' }: any) => {
    const [totalSupply, setTotalSupply] = useState<number | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [maturity, setMaturity] = useState(false)
    const [isApproved, setIsApproved] = useState<boolean>(false); // Add state for approval status
    const [depositAmount, setDepositAmount] = useState<number>(0);

    // const { provider, account } = useWeb3Auth();



    function shuffleArray<T>(array: T[]): T[] {
        const shuffledArray = [...array]; // Create a copy to avoid mutating the original array
        for (let i = shuffledArray.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1)); // Random index from 0 to i
            [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]]; // Swap elements
        }
        return shuffledArray;
    }

    const isApprovedForAll = async (web3: Web3, contract: any) => {

        //@ts-ignore
        let prov = window.ethereum;

        if (!prov) {
            toast.error('MetaMask is not installed');
            return;
        }

        const accounts = await prov.request({ method: 'eth_accounts' });
        const acc = accounts[0];

        try {
            const allowance = await contract.methods.allowance(acc, AIRDROP_CONTRACT_ADDRESS).call();
            return parseFloat(web3.utils.fromWei(allowance, 'ether')) >= depositAmount;
        } catch (err) {
            console.error(err);
            return false;
        }
    };

    const { connector, address, isConnected } = useAccount()

    useEffect(() => {
        const fetchContractData = async () => {


            const provid: any = await connector?.getProvider()
            const web3 = new Web3(provid)

            try {
                setLoading(true);
                // Connect to the Ethereum network
                const web3 = new Web3(provid);

                // Get the contract instance
                const contract = new web3.eth.Contract(AIRDROP_CONTRACT_ABI, AIRDROP_CONTRACT_ADDRESS);

                // Call the totalSupply function
                const totalAssets: any = await contract.methods.totalAssets().call();
                const formatAsset = Number(formatUnits(totalAssets.toString(), 6));
                setTotalSupply(formatAsset); // Assuming 18 decimals

            } catch (err) {
                console.error(err);
            } finally {
                setLoading(false);
            }
        };

        // if (account && provider) {
        fetchContractData();
        // }
    }, []);

    const apyData = [
        {
            "apy": 1.6264936136700727,
            "date": "Sat, 20 Jul 2024 00:00:00 GMT"
        },
        {
            "apy": 0.6035701152165236,
            "date": "Wed, 10 Jul 2024 00:00:00 GMT"
        },
        {
            "apy": 6.508426077032942,
            "date": "Sun, 30 Jun 2024 00:00:00 GMT"
        },
        {
            "apy": 5.29296183760178,
            "date": "Sat, 15 Jun 2024 00:00:00 GMT"
        },
        {
            "apy": 9.431126889312523,
            "date": "Sun, 09 Jun 2024 00:00:00 GMT"
        },
        {
            "apy": 5.7867547475416705,
            "date": "Mon, 03 Jun 2024 00:00:00 GMT"
        },
        {
            "apy": 3.936732593307805,
            "date": "Wed, 29 May 2024 00:00:00 GMT"
        },
        {
            "apy": 12.959680939840098,
            "date": "Wed, 24 Apr 2024 00:00:00 GMT"
        },
        {
            "apy": 22.158155325812018,
            "date": "Tue, 09 Apr 2024 00:00:00 GMT"
        },
        {
            "apy": 6.335566366654774,
            "date": "Wed, 27 Mar 2024 00:00:00 GMT"
        },
        {
            "apy": 0,
            "date": "Sun, 17 Mar 2024 00:00:00 GMT"
        }
    ]

    const tvlData = [
        {
            "apy": 1.6264936136700727,
            "date": "Sat, 20 Jul 2024 00:00:00 GMT"
        },
        {
            "apy": 0.6035701152165236,
            "date": "Wed, 10 Jul 2024 00:00:00 GMT"
        },
        {
            "apy": 6.508426077032942,
            "date": "Sun, 30 Jun 2024 00:00:00 GMT"
        },
        {
            "apy": 5.29296183760178,
            "date": "Sat, 15 Jun 2024 00:00:00 GMT"
        },
        {
            "apy": 9.431126889312523,
            "date": "Sun, 09 Jun 2024 00:00:00 GMT"
        },
        {
            "apy": 5.7867547475416705,
            "date": "Mon, 03 Jun 2024 00:00:00 GMT"
        },
        {
            "apy": 3.936732593307805,
            "date": "Wed, 29 May 2024 00:00:00 GMT"
        },
        {
            "apy": 12.959680939840098,
            "date": "Wed, 24 Apr 2024 00:00:00 GMT"
        },
        {
            "apy": 22.158155325812018,
            "date": "Tue, 09 Apr 2024 00:00:00 GMT"
        },
        {
            "apy": 6.335566366654774,
            "date": "Wed, 27 Mar 2024 00:00:00 GMT"
        },
        {
            "apy": 0,
            "date": "Sun, 17 Mar 2024 00:00:00 GMT"
        }
    ]

    return (
        <div className='rounded-3xl  bg-white border border-cardStroke p-4' >
            <div className="flex gap-3 items-center">
                <div className="w-8 h-8 rounded-full bg-cardStroke"></div>
                <p className='text-[20px]'>{card.name}</p>
            </div>
            <p className=' text-sm text-textSecondary py-3'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
            <div className="flex gap-2">
                <div className="bg-cardStroke w-5 h-5 rounded-full "></div>
                <div className="bg-cardStroke w-5 h-5 rounded-full "></div>
                <div className="bg-cardStroke w-5 h-5 rounded-full "></div>
                <div className="bg-cardStroke w-5 h-5 rounded-full "></div>
                <div className="bg-cardStroke w-5 h-5 rounded-full "></div>
            </div>
            <div className=" text-sm pt-3">
                <div className="flex justify-between mb-2">
                    <span className=' text-textSecondary'>Max Participants</span>
                    <span>{card.maxParticipants}</span>
                </div>
                <div className="flex justify-between mb-2">
                    <span className=' text-textSecondary'>Lock-up duration</span>
                    <span>{card.lockUpDuration}</span>
                </div>
                <div className="flex justify-between mb-2">
                    <span className=' text-textSecondary'>Amount Raised</span>
                    <span>{totalSupply ?? '--'}</span>
                </div>
                <div className="flex justify-between mb-2">
                    <span className=' text-textSecondary'>Estimated Airdrop Timeline</span>
                    <span>{card.estimatedAirdropTimeline}</span>
                </div>
            </div>
            <div className="flex gap-2">
                <div className="flex border flex-1 border-cardStroke rounded-3xl p-2 justify-between items-center ">
                    <p className='text-textSecondary text-sm'>Token <br /> Value</p>
                    <p>{card.tokenValue}</p>
                </div>
                <div className="flex border flex-1 border-cardStroke rounded-3xl p-2 justify-between items-center ">
                    <p className='text-textSecondary text-sm'>Token <br /> Market Cap</p>
                    <p>{card.tokenMarketCap}</p>
                </div>
            </div>
            <div className=" flex justify-stretch w-full mt-3 gap-2 text-sm ">
                <div className=" bg-cardStroke flex-1 items-center flex text-sm rounded-2xl p-2 ">
                    <div className="flex flex-1 flex-col">
                        <p className=' text-textSecondary ' >APY</p>
                        <p>112.04%</p>
                    </div>
                    <LineChart width={50} height={30} data={shuffleArray(apyData)}>
                        <Line type="monotone" dot={false} dataKey="apy" stroke="#85C453" />
                    </LineChart>

                </div>
                <div className=" bg-cardStroke flex-1 items-center flex text-sm rounded-2xl p-2  ">
                    <div className="flex flex-1 flex-col">
                        <p className=' text-textSecondary ' >TVL</p>
                        <p>112.04%</p>
                    </div>
                    <LineChart width={50} height={30} data={shuffleArray(tvlData)}>
                        <Line type="monotone" dot={false} dataKey="apy" stroke="#9475FF" isAnimationActive={true}
                            animationDuration={1500}
                            animationEasing="ease-in-out" />
                    </LineChart>

                </div>
            </div>
            {
                type === 'deposit' ? (
                    <ActivityModal data={card} />
                ) : (
                    <WithdrawActivityModal data={card} />
                )
            }
        </div>
    )
}

export default ActivityCard