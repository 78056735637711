import React, { FC } from "react";
import { Link } from "react-router-dom";

interface FooterProps {}

const Footer: FC<FooterProps> = ({}) => {
  return (
    <div className="w-full p-6 absolute bottom-0 flex-col md:flex-row gap-2 flex justify-between text-white items-center">
      <p className=" text-border-200 text-sm">
        YieldSack
      </p>
      <div className="flex justify-end gap-3 ">
        <Link
          to={""}
          target="_blank"
          className=" rounded-full bg-secondary-200 flex justify-center items-center p-[5px] "
        >
          <img src={"/telegram.svg"} className=" w-[14px]" alt="" />
        </Link>
        <Link
          to={""}
          target="_blank"
          className=" rounded-full bg-secondary-200 flex justify-center items-center p-[5px] "
        >
          <img src={"/medium.svg"} className=" w-[14px]" alt="" />
        </Link>
        {/* <Link to={"https://twitter.com/PositionsFi"} target='_blank' className=" rounded-full bg-secondary-200 flex justify-center items-center p-[5px] "><img src={"/twitter.svg"} className=' w-[14px] ' alt="" /></Link> */}
        <Link
          to={""}
          target="_blank"
          className=" rounded-full bg-secondary-200 flex justify-center items-center p-[5px] "
        >
          <img src={"/discord.svg"} className=" w-[14px]" alt="" />
        </Link>
      </div>
    </div>
  );
};

export default Footer;
