import YieldCard from "../components/Withdraw/YieldCardWithdraw";
import { activitiesData, cardData, formatWalletAddress } from "../utils/utils";
import { useAccount } from "wagmi";
import HistoryElement from "src/components/portfolio/HistoryElement";
import {
  CartesianGrid,
  Cell,
  Label,
  Line,
  LineChart,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import * as Tabs from "@radix-ui/react-tabs";
import { useEffect, useState } from "react";

import ActivityCard from "src/components/activities/ActivityCard";

import axios from "axios";
import Web3 from "web3";
import { Divide, Loader2 } from "lucide-react";
import moment from "moment-timezone";
import { formatEther } from "viem";
import { aggregateData } from "src/utils/functions/aggregateData";

const API_BASE_URL = process.env.REACT_APP_API_URL;
const durations = ["7d", "30d", "All Time"];

function Portfolio() {
  const { address, connector, isConnected } = useAccount();
  const [tabs, setTabs] = useState("yields");
  const [duration, setDuration] = useState<"7d" | "30d" | "All Time">("7d");
  const [history, setHistory] = useState<any>([]);
  const [filteredHistory, setFilteredHistory] = useState<any>([]);
  const [isChartLoading, setIsChartLoading] = useState(false);
  const [vaultAddresses, setVaultAddresses] = useState([]);
  const [filteredVaultAddressData, setFilteredVaultAddressData] = useState<any>(
    []
  );
  const [portfolioAmount, setPortfolioAmount] = useState<any>("...");
  const [portfolioChartData, setPortfolioChartData] = useState<any>([]);

  const [isLoading, setIsLoading] = useState(true);

  const getHistory = async () => {
    try {
      setIsLoading(true);
      const data = await axios.get(`${API_BASE_URL}/history/wallet/${address}`);
      setHistory(data?.data);
      setFilteredHistory(data?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const DurationHandler = async (duration: "7d" | "30d" | "All Time") => {
    setDuration(duration);

    const now = new Date();
    let startDate: Date;

    switch (duration) {
      case "7d":
        startDate = new Date(now.setDate(now.getDate() - 7));
        break;
      case "30d":
        startDate = new Date(now.setDate(now.getDate() - 30));
        break;
      case "All Time":
      default:
        startDate = new Date(0); // Epoch time
        break;
    }

    try {
      setIsLoading(true);
      const filtered = history.filter(
        (item: any) => moment(startDate).format() <= moment(item?.date).format()
      );
      setFilteredHistory(filtered);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const legends = [
    {
      name: "EigenLayer",
      color: "#0088FE",
      value: 400,
    },
    {
      name: "EtherFi",
      color: "#00C49F",
      value: 300,
    },
    {
      name: "LiquidETH",
      color: "#FF8042",
      value: 200,
    },
    {
      name: "Symbiotic",
      color: "#FFA500",
      value: 100,
    },
    {
      name: "Ethena",
      color: "#FF8042",
      value: 300,
    },
  ];

  const getDepositAmount = async (
    contractAddress: string,
    contractAbi: any,
    amountFunction: any
  ) => {
    try {
      const provider: any = await connector?.getProvider();
      const web3 = new Web3(provider);
      const contract = new web3.eth.Contract(contractAbi, contractAddress);
      if (!amountFunction) return;
      // const amount: any = await contract.methods[amountFunction](
      //   "0x74e07b68f9e9e7fe6a684691653f27c5fd4e6eee"
      // ).call();
      const amount: any =
        await contract.methods[amountFunction](address).call();
      return Number(amount.deposit);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    let vaultAddress: any = [];

    if (isConnected) {
      const fetchAllData = async () => {
        setIsChartLoading(true);
        for (const card of cardData) {
          const amount = await getDepositAmount(
            card.contractAddress,
            card.contractAbi,
            card.amountFunction
          );
          const apyChart = await fetchApyDataForAddress(card.vaultAddress);

          vaultAddress.push({
            vaultAddress: card.vaultAddress,
            contractAddress: card.contractAddress,
            amount: amount ? amount / Math.pow(10, 18) : 0,
            chart: apyChart?.apy_chart,
            color: legends[card.id - 1].color,
            name: card.strategy,
          });
          // vaultAddress.push({ vaultAddress: card.vaultAddress, contractAddress: card.contractAddress, amount: formatEther(BigInt(amount ?? 0 as any ) ), chart: apyChart.apy_chart, color: legends[card.id - 1].color ,name:card.strategy});
        }
        const total = vaultAddress.reduce(
          (acc: any, curr: any) => acc + Number(curr.amount),
          0
        );
        setPortfolioAmount(Number(total).toFixed(6).toString());

        setVaultAddresses(vaultAddress);
        setFilteredVaultAddressData(vaultAddress);

        setIsChartLoading(false);
      };

      fetchAllData();
      getHistory();

      // setVaultAddresses(vaultAddress)
      // fetchData(vaultAddress)
    }
  }, [isConnected]);

  const getPortfolioData = async () => {
    try {
      const res = await axios.get(
        `${API_BASE_URL}/users/deposit_balance/${address}/${duration.toLowerCase()}`
      );
      // const res = await axios.get(
      //   `${API_BASE_URL}/users/deposit_balance/0x74e07b68f9e9e7fe6a684691653f27c5fd4e6eee/${duration.toLowerCase()}`
      // );
      setPortfolioChartData(aggregateData(res.data));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (isConnected) {
      getPortfolioData();
    }
  }, [isConnected, duration]);

  const fetchApyDataForAddress = async (vaultAddress: string) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/apy/${vaultAddress}`);
      return response.data; // Assuming you want to return the data property of the response
    } catch (error) {
      console.error(
        "Error fetching APY data for address:",
        vaultAddress,
        error
      );
    }
  };

  const isAllDataZero = (data: any) => {
    let allZero = true;
    data?.map((item: any) => {
      if (item.amount != 0) {
        allZero = false;
      }
    });
    return allZero;
  };

  return (
    <div className=" w-full ">
      <div className=" mx-auto rounded-3xl w-[90%] xl:w-[80%] flex-col lg:flex-row border border-cardStroke mt-6 lg:mt-10 flex ">
        <div className="flex flex-1 flex-col lg:flex-row justify-between items-center text-black/80 p-6">
          <p className="flex gap-2 p-2 text-xl orbitron-400">
            {formatWalletAddress(address as string) ??
              "Connect Wallet Your Wallet"}
          </p>
        </div>
        <div className="  p-5 flex flex-col justify-center items-start ">
          <p className=" text-sm ">Portfolio Amount</p>
          <p className=" text-xl orbitron-400 pt-2 ">{portfolioAmount} </p>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-12 gap-4 mx-auto w-[90%] xl:w-[80%] mt-4 ">
        <div className="lg:col-span-9 flex flex-col border rounded-3xl border-secondaryBg ">
          <div className="border-b border-cardStroke p-4 flex justify-between items-center">
            <p className=" orbitron-400 ">Portfolio Asset Distribution</p>
            <p className=" text-textSecondary text-xs ">
              {" "}
              Last updated: 24 july{" "}
            </p>
          </div>

          <div className="border-b flex flex-col gap-2 lg:flex-row justify-between items-center border-cardStroke px-4 py-3 ">
            <div className=" flex justify-start flex-wrap items-center gap-4 ">
              {legends?.map((legend, index) => (
                <div className="flex gap-2 items-center">
                  <div
                    className={` w-3 h-3 rounded-full `}
                    style={{ backgroundColor: legend.color }}
                  ></div>
                  <p className="text-xs">{legend.name}</p>
                </div>
              ))}
            </div>
            <div className=" bg-cardBg rounded-3xl text-xs border-cardStroke border flex items-center justify-center ">
              {durations?.map((dur: any, i: any) => (
                <p
                  onClick={() => DurationHandler(dur)}
                  className={`py-[6px] bg-cardStroke border px-4 cursor-pointer ${
                    dur === duration
                      ? `bg-white text-black ${i === 0 && " rounded-l-lg"}
                  ${i === 2 && " rounded-r-lg"}`
                      : ""
                  }  `}
                >
                  {dur}
                </p>
              ))}
              {/* <p className="py-[6px] px-4 " >7d</p>
              <p className="py-[6px] px-4  border-l border-cardStroke" >All Time</p>
              <p className="py-[6px] px-4  border-l border-cardStroke bg-white text-black rounded-r-lg" >Mock Data</p> */}
            </div>
          </div>

          <div className="  flex-1 grid grid-cols-1 lg:grid-cols-12 justify-center items-center w-full  ">
            <div className=" flex-1 lg:col-span-7  h-full p-6 flex justify-center items-center">
              <ResponsiveContainer className={""} height={250} width="100%">
                {portfolioChartData.length > 0 ? (
                  <LineChart
                    width={500}
                    height={300}
                    data={portfolioChartData}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="timestamp" />
                    <YAxis />
                    <Tooltip />
                    <Line
                      type="monotone"
                      dataKey="eigenlayer"
                      stroke="#8884d8"
                      dot={false}
                    />
                    <Line
                      type="monotone"
                      dataKey="etherfi"
                      stroke="#82ca9d"
                      dot={false}
                    />
                    <Line
                      type="monotone"
                      dataKey="ethena"
                      stroke="#FF8042"
                      dot={false}
                    />
                    <Line
                      type="monotone"
                      dataKey="symbiotic"
                      stroke="#FFA500"
                      dot={false}
                    />
                    <Line
                      type="monotone"
                      dataKey="liquideth"
                      stroke="#FF8042"
                      dot={false}
                    />
                  </LineChart>
                ) : (
                  <div className="mx-auto w-full h-full flex flex-col-reverse text-gray-500">
                    Data will be visible 24hrs after deposit
                  </div>
                )}
              </ResponsiveContainer>
            </div>
            <div className=" flex justify-center items-center h-full lg:col-span-5 border-cardStroke lg:border-l border-t lg:border-t-0 p-6 ">
              {isChartLoading ? (
                <div className="w-full h-full flex justify-center items-center">
                  <Loader2 size={32} className=" animate-spin " />
                </div>
              ) : !isAllDataZero(filteredVaultAddressData) ? (
                <>
                  <ResponsiveContainer className={""} height={250} width="100%">
                    <PieChart>
                      <Pie
                        className=""
                        minAngle={30}
                        data={filteredVaultAddressData}
                        dataKey="amount"
                        innerRadius={55}
                        outerRadius={120}
                        startAngle={90}
                        endAngle={450}
                        stroke="none"
                      >
                        {filteredVaultAddressData?.map((entry: any) => (
                          <Cell fill={entry.color} />
                        ))}
                      </Pie>
                      <Tooltip />
                    </PieChart>
                  </ResponsiveContainer>
                </>
              ) : (
                <>
                  <ResponsiveContainer className={""} height={250} width="100%">
                    <PieChart>
                      <Pie
                        className=""
                        minAngle={30}
                        data={[
                          {
                            color: "#0088FE",
                            name: "none",
                            amount: 100,
                            label: "no Data",
                          },
                        ]}
                        dataKey="amount"
                        innerRadius={55}
                        outerRadius={120}
                        startAngle={90}
                        endAngle={450}
                        stroke="none"
                      >
                        {filteredVaultAddressData?.map((entry: any) => (
                          <Cell fill={entry.color} />
                        ))}
                        <Label value="No Data" offset={0} position="center" />
                      </Pie>
                      <Tooltip />
                    </PieChart>
                  </ResponsiveContainer>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="bg-white border-cardStroke   rounded-3xl inner-scroll max-h-[500px] overflow-y-auto border lg:col-span-3  w-full">
          <div className=" bg-white border-b border-cardStroke orbitron-400 px-[6px] py-2 ">
            History
          </div>
          <div className="py-2 h-full">
            {isLoading ? (
              <div className="w-full h-full flex justify-center items-center">
                <Loader2 size={32} className=" animate-spin " />
              </div>
            ) : filteredHistory && filteredHistory.length > 0 ? (
              filteredHistory?.map((history: any) => (
                <HistoryElement history={history} />
              ))
            ) : (
              <div className="w-full h-full flex justify-center items-center">
                <p className="text-textSecondary">No history available</p>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* <div className=" px-6 xl:px-0 pt-6  w-full xl:w-[80%] mx-auto   ">
        <Tabs.Root value={tabs} onValueChange={(e) => setTabs(e)} className="">
          <div className="flex gap-5 flex-col md:flex-row mb-6">
            <h2 className="orbitron-400 text-2xl ">Investments</h2>
            <Tabs.List className="flex bg-cardBg border border-cardStroke rounded-md text-textSecondary ">
              <Tabs.Trigger
                value="yields"
                className={`text-textSecondary text-sm rounded-md flex justify-center items-center px-3 py-2 ${tabs === "yields" ? "bg-white text-black" : ""} `}
              >
                Yield Staking
              </Tabs.Trigger>
              <Tabs.Trigger
                value="airdrop"
                className={`text-textSecondary  rounded-md text-sm flex justify-center items-center px-3 py-2 ${tabs === "airdrop" ? "bg-white text-black" : ""}`}
              >
                Airdrop Farming
              </Tabs.Trigger>
            </Tabs.List>
          </div>
          <Tabs.Content value="yields" className="">
            <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4  ">
              {cardData?.map((card, index) => (
                <YieldCard card={card} deposited={true} />
              ))}
            </div>
          </Tabs.Content>
          <Tabs.Content value="airdrop" className="">
            <div className=" grid grid-cols-1 md:grid-cols-2 gap-4  ">
              {activitiesData?.map((card, index) => (
                <ActivityCard card={card} type="withdraw" />
              ))}
            </div>
          </Tabs.Content>
        </Tabs.Root>
      </div> */}
    </div>
  );
}

export default Portfolio;
