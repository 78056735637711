import ActivityCard from '../components/activities/ActivityCard'
import { activitiesData } from '../utils/utils'

const Activities = () => {

  return (
    <div className=" w-full ">
      <div className=" px-6 xl:px-0 pt-6  w-full xl:w-[80%] mx-auto   " >
        <div className=" grid grid-cols-1 md:grid-cols-2 gap-4  ">
          {
            activitiesData.map((card, index) => (
              <ActivityCard card={card} />
            ))
          }
        </div>
      </div>

    </div>
  )
}

export default Activities