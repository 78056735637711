import React, { useState } from 'react'
import { Loader2, RotateCcw } from 'lucide-react'
import { motion, AnimatePresence } from "framer-motion";

interface DepositButtonProps {
    handler: () => void;
    disabled: boolean;
    modalState: string;
    setModalState: any;
    buttonCopy: any
}

const DepositButton = ({handler,disabled= false,modalState,setModalState,buttonCopy}: DepositButtonProps)  => {


    return (
        <button className={` overflow-hidden text-white  py-3  text-center ${modalState === ('error' || 'retry')  ? 'bg-[#FF6666]  ': 'bg-highlight'} transition-colors duration-200 rounded-3xl  w-full   `}
            disabled={modalState === "loading"}
            onClick={handler}
        >
            <AnimatePresence mode="popLayout" initial={false}>
                <motion.span
                    transition={{ type: "spring", duration: 0.3, bounce: 0 }}
                    initial={{ opacity: 0, y: -25 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 25 }}
                    key={modalState}
                    className='flex justify-center items-center gap-2 w-full'
                >
                    {buttonCopy[modalState]}
                </motion.span>
            </AnimatePresence>
        </button>
    )
}

export default DepositButton