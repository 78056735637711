import React, { FC } from 'react'
import { BrowserRouter } from 'react-router-dom';

interface ProvidersProps {
  children: React.ReactNode;
}

const Providers: FC<ProvidersProps> = ({ children }) => {
  return (
    <BrowserRouter>
     {children}
    </BrowserRouter>
  )
}

export default Providers;