interface ResultData {
  _id: string;
  wallet_address: string;
  balance: number;
  strategy: string;
  timestamp: string;
  __v: number;
}

interface AggregatedData {
  name: string;
  eigenlayer: number;
  etherfi: number;
  ethena: number;
  amt: number;
}

export const aggregateData = (data: {
  [strategy: string]: ResultData[];
}): AggregatedData[] => {
  const result: { [date: string]: AggregatedData } = {};

  for (const strategy in data) {
    for (const record of data[strategy]) {
      const date = record.timestamp.split("T")[0]; // Extract date part from timestamp

      if (!result[date]) {
        result[date] = {
          name: date,
          eigenlayer: 0,
          etherfi: 0,
          ethena: 0,
          amt: 0,
        };
      }

      if (record.strategy === "EigenLayer") {
        result[date].eigenlayer = record.balance / Math.pow(10, 18);
      } else if (record.strategy === "EtherFi") {
        result[date].etherfi = record.balance / Math.pow(10, 18);
      } else if (record.strategy === "Ethena") {
        result[date].ethena = record.balance / Math.pow(10, 18);
      }
      result[date].amt =
        (result[date].eigenlayer + result[date].etherfi + result[date].ethena) /
        3;
    }
  }

  return Object.values(result);
};
