import React, { FC, useEffect, useState } from "react";
import Web3 from "web3";
import { useWeb3Auth } from "../utils/hooks";
import DepositModal from "./DespositModal/DepositModal";
import { LineChart, Line, XAxis } from "recharts";
import { useApyData } from "../api/queries";
import axios from "axios";
import { CardData } from "../utils/types";
import { formatCompactNumber } from "../utils/utils";
import { formatEther } from "viem";
import { useAccount } from "wagmi";

let web3;
let account;
let provider;

// import abi from './abi.json';  // Assuming abi.json is in the same directory

interface YieldCardProps {
  card: CardData;
  deposited: boolean;
  address: any;
  balance: any;
}

const YieldCard: FC<YieldCardProps> = ({
  card,
  deposited,
  address,
  balance,
}) => {
  const { data, isLoading } = useApyData(card.vaultAddress);
  const { isConnected, connector } = useAccount();
  const [etherFiTvl, setEtherFiTvl] = useState(0);

  const getEtherFiTVL = async () => {
    try {
      const response = await axios.get(
        "https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD"
      );

      const provid: any = await connector?.getProvider();
      const web3 = new Web3(provid);

      const contractBalance = await web3.eth.getBalance(
        "0x308861A430be4cce5502d0A12724771Fc6DaF216"
      );

      console.log(
        (formatEther(contractBalance) as any) * response?.data?.USD,
        "val"
      );
      setEtherFiTvl(
        (formatEther(contractBalance) as any) * response?.data?.USD
      );
    } catch (error) {
      console.error("Error fetching ETH price:", error);
    }
  };

  useEffect(() => {
    if (isConnected && card?.strategy === "EtherFi Staking") {
      getEtherFiTVL();
    }
  }, [isConnected]);

  return (
    <div className=" rounded-3xl  border border-cardStroke   bg-white  p-4  ">
      <div className="flex gap-2 ">
        <div className="flex">
          {card.protocol.map((protocol: any, index: number) => (
            <img
              src={protocol.image}
              className={`${index > 0 ? "z-10  -translate-x-2 " : ""}`}
              alt=""
            />
          ))}
        </div>
        <p className="orbitron-400"> {card.strategy}</p>
      </div>

      <div className=" text-sm mt-6 ">
        <div className=" flex justify-between items-center">
          <p className=" text-textSecondary ">Token</p>
          <p>{card.token}</p>
        </div>
        <div className=" flex pt-2   justify-between items-center">
          <p className=" text-textSecondary ">Chain(s)</p>
          <div className="flex gap-1">
            <img src="/eth.svg" alt="" />

            <p>Ethereum</p>
          </div>
        </div>
        <div className=" flex pt-2 justify-between items-center">
          <p className=" text-textSecondary ">Protocol</p>
          <p>
            <div className="flex gap-2">
              {card.protocol.map((protocol: any) => (
                <div className="flex gap-2">
                  <img src={protocol.image} className=" w-4 h-4 " alt="" />
                  <p>{protocol.name}</p>
                </div>
              ))}
            </div>
          </p>
        </div>

        <div className=" flex justify-stretch w-full mt-5 gap-2 text-sm ">
          <div className=" bg-cardStroke flex-1 items-center flex text-sm rounded-2xl px-3 py-2 ">
            <div className="flex flex-1 flex-col">
              <p className="text-textSecondary">APY</p>
              <p>{isLoading ? "..." : data?.data?.average_apy.toFixed(2)}%</p>
            </div>
            <LineChart width={50} height={30} data={data?.data?.apy_chart}>
              <Line
                type="monotone"
                dot={false}
                dataKey="apy"
                stroke="#85C453"
              />
            </LineChart>
          </div>
          <div className=" bg-cardStroke flex-1 items-center flex text-sm rounded-2xl p-2  ">
            <div className="flex flex-1 flex-col">
              <p className=" text-textSecondary   ">TVL</p>
              <p>
                {isLoading
                  ? "..."
                  : data?.data?.tvl_chart[0]?.tvl > 0 &&
                    formatCompactNumber(data?.data?.tvl_chart[0]?.tvl)}
              </p>
              <p>
                {card?.strategy === "EigenLayer Staking" && "1.1B"}
                {card?.strategy === "Ethena USDe Staking" && "825M"}
                {card?.strategy === "EtherFi Staking" &&
                  formatCompactNumber(etherFiTvl.toFixed(2))}
              </p>
            </div>
            <LineChart width={50} height={30} data={data?.data?.tvl_chart}>
              <XAxis dataKey={"date"} />
              <Line
                type="monotone"
                dot={false}
                dataKey="tvl"
                stroke="#9475FF"
                isAnimationActive={true}
                animationDuration={1500}
                animationEasing="ease-in-out"
              />
            </LineChart>
          </div>
        </div>
        <div className=" flex flex-col gap-2 mt-6 ">
          <DepositModal
            address={address}
            balance={balance}
            data={card}
            apyChart={data?.data?.apy_chart}
            apyAvg={data?.data?.average_apy.toFixed(2)}
            tvlChart={data?.data?.tvl_chart}
            etherFiTvl={formatCompactNumber(etherFiTvl.toFixed(2))}
            disabled={card?.id === 3}
          />
        </div>
      </div>
    </div>
  );
};

export default YieldCard;
